import { BaseModal } from 'components/BaseModal'
import NewModalComponent from 'components/new-modal'
import * as $ from 'jquery'
import React, { FC, useEffect, useState } from 'react'
import DirectSaleParameter from './direct-sale-parameter'
import './index.scss'

export const MoreInformationModal: FC<any> = ({
    company,
    onCloseCallback,
    states,
}: any) => {
    const [modal, setModal] = useState<any>({})




    useEffect(() => {
        setModal({
            title: `Parâmetros da venda direta: ${company?.name}`,
            id: 'company-info-modal',

            styles: {
                maxWidth: '80vw',
            },
            onClose: onCloseCallback,
            submit: null,
            disabledButtons: true
        })
    }, [company, onCloseCallback])



    const close_modal = () => {
        $('#' + modal.id).removeClass('fadeIn')
        $('#' + modal.id).addClass('fadeOutUp')
        setTimeout(() => {
            $('#' + modal.id).modal('hide')
            $('#' + modal.id).removeClass('fadeOutUp')
            $('#' + modal.id).addClass('fadeIn')
        }, 700)
    }



    return (
        <NewModalComponent modal={modal}>
            {company &&
                <div style={{ padding: '15px 20px' }}>
                    <DirectSaleParameter
                        states={states}
                        company={company}
                        onCloseCallback={onCloseCallback}
                        close_modal={close_modal}
                    />
                </div>}
        </NewModalComponent >
    )
}
