import ButtonV3 from 'components/Buttonv3'
import { CheckBoxCustom } from 'components/checkBoxCustom'
import { Currency } from 'components/currency-input'
import { Select } from 'components/select'
import { Snackbar } from 'components/snackbar'
import { UserContext } from 'contexts/user'
import { Salesman } from 'interfaces/salesman'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { useSnackbar } from 'react-simple-snackbar'
import ProductService from 'services/ProductService'
import SalesmanService from 'services/SalesmanService'
import SnackBarSuccessStyle from 'styles/snackbar-success.style'
import { ProviderConditions, Route } from '../../../../../interfaces/provider'
import { Weekday } from '../../../../../interfaces/weekday'
import DateService from '../../../../../services/DateService'
import ProviderService from '../../../../../services/ProviderService'
import ClientServiceV2 from '../../../../../services/v2/ClientService'
import Utils from '../../../../../utils'
import './index.scss'
import Switch from "react-switch"
import { Input } from 'components/input'
import { ReactComponent as Cardadd } from "../../../../../assets/cardadd.svg";
import { VscDebugConsole, VscGear } from 'react-icons/vsc'
import { FaStore } from "react-icons/fa";
import ToggleButton from 'react-toggle-button'
import { SalesTaxesService } from 'services/v2/sales.taxes'
import UserService from 'services/UserService'
import DeliveryRoutesService from 'services/v3/deliveryRoutesService'
import ClientService from 'services/v3/clients.service'
import { FiMapPin } from "react-icons/fi";


import { ToastContainer, toast } from 'react-toastify';


const DirectSaleParameter = ({
    company,
    onCloseCallback,
    close_modal,
    states,
}: any) => {
    const { handleSubmit, register, errors } = useForm();
    const [acceptPix, setAcceptPix] = useState(0);
    const [minimumWithdrawalBilling, setMinimumWithdrawalBilling] = useState(0)
    const [minimumDeliveryBilling, setMinimumDeliveryBilling] = useState(0)
    const [delivery, setDelivery] = useState('0')
    const [group, setGroup] = useState<any>()
    const [groups, setGroups] = useState([])
    const [isCpf, setIsCpf] = useState(true)
    const [fractionalPurchase, setFractionalPurchase] = useState('0')
    const [route, setRoute] = useState<any>()
    const [level, setLevel] = useState<any>()
    const [levelList, setLevelList] = useState<any>()
    const [routes, setRoutes] = useState<Route[]>([])
    const [deliveryRoutes, setDeliveryRoutes] = useState<any>([])
    const [checkedIds, setCheckedIds] = useState<any>([])


    const [willPost, setWillPost] = useState<boolean>(false)
    const [days, setDays] = useState<Array<any>>([])
    const { user } = useContext(UserContext)
    const [salesman, setSalesmen] = useState<any>()
    const [listSales, setListSales] = useState<Salesman[]>([])
    const [fieldCOntrol, setFieldControl] = useState(0)
    const [openSnackbar] = useSnackbar(SnackBarSuccessStyle)
    const [visibleView, setVisibleView] = useState('CONFIGS')
    const [salesTaxes, setSalesTaxes] = useState<any>()
    const [activeSalesTaxes, setActiveSalesTaxes] = useState<any>()
    const [needMinDeliveryBilling, setNeedMinDeliveryBilling] = useState(false)
    const [needMinWithdrawalBilling, setNeedMinWithdrawalBilling] = useState(false)

    const [cityOptions, setCityOptions] = useState([])

    const [cpf, setCpf] = useState<number>()
    const [social, setSocial] = useState('')
    const [name, setName] = useState('')
    const [state, setState] = useState(0)
    const [city, setCity] = useState(0)
    const [district, setDistrict] = useState('')
    const [address, setAddress] = useState('')
    const [cep, setCep] = useState(0)
    const [number, setNumber] = useState(0)
    const [complement, setComplement] = useState('')
    const [conditionId, setConditionId] = useState<number>(0)

    const [regions, setRegions] = useState<any>(null);

    const [testeSwitch, setTesteSwitch] = useState(false)

    async function loadRegions() {
        const { estados, cidades } = await UserService.regions();
        setRegions({ estados, cidades })

    }

    
    useEffect(()=>{
        setCityOptions(regions?.cidades.filter(city => city.est_id == state))
    }, [state])

    useEffect(() => {
        loadRegions()

        const setConst = (consts: ProviderConditions) => {
            
            setMinimumWithdrawalBilling(consts.minimumWithdrawalBilling)
            setMinimumDeliveryBilling(consts.minimumDeliveryBilling)
            setDelivery(consts.delivery)
            setRoute(consts.route || {})
            setLevel(consts.level)
            setGroup(consts.group_id)
            setSalesmen(consts.seller_id)
            setFractionalPurchase(consts.fractional_purchase);
            setAcceptPix(consts.is_accepting_pix || 0);
            let activeTaxes = consts.taxesCondition.map(item => item.id)
            setActiveSalesTaxes(activeTaxes)
            setFieldControl(consts.has_field_control)
            setConditionId(consts.id)
            setName(consts.name || '')
            setSocial(consts.social || '')
            setCpf(consts.cpf || 0)
            setDistrict(consts.district || '')
            setAddress(consts.address || '')
            setNumber(consts.number || 0)
            setComplement(consts.complement || '')
            setCep(consts.cep || 0)
            setState(consts.state || 0)
            setCity(consts.city || 0)
            setIsCpf(consts.isCpf === 0 ? false : true)
        }


        ProviderService.getClientCondition(user.providerId, company.id).then(
            (response) => (response ? setConst(response) : setWillPost(true))
        )

        ProviderService.getProviderLevelsAll()
            .then(res => { setLevelList(res) })

        ProviderService.getProviderRoutes(user.providerId).then((response) =>
            setRoutes(response)
        )

        ClientServiceV2.deliveries(company.id).then((response) =>
            setDays(response.map((day: any) => day.tec_dia_semana))
        )

        SalesmanService.getSalesManForStore(user.providerId, company.id).then(
            (response) => {
                setListSales(response)
            }
        )
        DeliveryRoutesService.getDeliveryRoutes().then(res => {
            if (res.length > 0) {
                let idsLojas: any = [];
                res.forEach(item => {
                    if (item.lojas && item.lojas.length > 0) {
                        item.lojas.forEach(loja => {
                            if (loja.id_loja == company.id) {
                                idsLojas.push(loja.id_local_retirada);
                            }
                        });
                    }
                });
                setCheckedIds(idsLojas)
            }
            setDeliveryRoutes(res)
        }
        )
        ProductService.groups(user.providerId).then((result) => {
            setGroups(result.groups)
        })
        handleGetTaxes()
    }, [company, user])


    function handleCityName(id) {
        let cidade
        if (regions && regions.cidades) {
            cidade = regions.cidades.filter(item => Number(item.cid_id) == Number(id))[0]
        }
        return cidade && cidade.cid_nome ? cidade.cid_nome : '-'
    }
    function handlStateName(id) {
        let estado
        if (regions && regions.estados) {
            estado = regions.estados.filter(item => Number(item.est_id) == Number(id))[0]
        }
        return estado && estado.est_nome ? estado.est_nome : '-'
    }

    const check = (event: any) => {
        const value = parseInt(event)
        if (!days?.includes(value)) {
            const newVal = days?.slice()
            newVal?.push(value)
            setDays(newVal)
        } else {
            setDays(days?.filter((d) => d !== value))
        }
    }

    const onSubmit = () => {
        if (visibleView == 'COMPANY') {
            console.log(state);
            console.log();
            
            
            console.log(regions.estados.filter(item => Number(item.est_id) == Number(state))[0].est_id);
            
            ClientService.updateCondition(conditionId,{
                "CNPJ": cpf,
                "is_cpf": isCpf,
                "nome_fantasia": name,
                "razao_social": social,
                "cep": cep,
                "bairro": district,
                "endereco": address,
                "numero": number,
                "complemento": complement,
                "cod_estado": regions.estados.filter(item => Number(item.est_id) == Number(state))[0].est_id,
                "cod_cidade": city
            })
            return
        }
        if (delivery == '1') {
            if (minimumDeliveryBilling < 1) {
                setNeedMinDeliveryBilling(true)
                return
            } else {
                setNeedMinDeliveryBilling(false)
            }
        }
        if (minimumWithdrawalBilling < 1) {
            setNeedMinWithdrawalBilling(true)
            return
        } else {
            setNeedMinWithdrawalBilling(false)
        }

        const data = {
            entrega: delivery,
            'fat-min-entrega': minimumDeliveryBilling
                ? Utils.formatPriceToApiFix(minimumDeliveryBilling.toString())
                : 0,
            'fat-min-retirada': minimumWithdrawalBilling
                ? Utils.formatPriceToApiFix(minimumWithdrawalBilling.toString())
                : 0,
            rota: route,
            'nivel-preco': level,
            vendedor: salesman,
            compra_fracionada: parseInt(fractionalPurchase),
            grupo: group,
            is_accepting_pix: acceptPix ? 1 : 0,
            field_control: fieldCOntrol,
            taxes: activeSalesTaxes
        }


        willPost
            // false
            ? ProviderService.postClientCondition(
                user.providerId,
                company.id,
                data
            ).then(() => {
                onCloseCallback()
                close_modal()
                openSnackbar(
                    <Snackbar icon="thumb_up">
                        Informações enviadas com sucesso!
                    </Snackbar>
                )
            })
            : ProviderService.putClientCondition(
                user.providerId,
                company.id,
                data
            ).then(() => {
                onCloseCallback()
                close_modal()
                openSnackbar(
                    <Snackbar icon="thumb_up">
                        Informações atualizadas com sucesso!
                    </Snackbar>
                )
            })

            ClientServiceV2.saveDeliveries(
            company.id,
            days.map((day) => ({ tec_dia_semana: day }))
        )
    }
    async function handleGetTaxes() {
        let salesTaxesdata = await SalesTaxesService.getSalesTaxes()
        setSalesTaxes(salesTaxesdata)
    }
    function handleActiveTaxes(itemId) {
        if (activeSalesTaxes.includes(itemId)) {
            let actualActive = activeSalesTaxes.filter(item => item != itemId)
            setActiveSalesTaxes(actualActive)
        } else {
            let actualActive = activeSalesTaxes
            actualActive.push(itemId)
            setActiveSalesTaxes(actualActive)
        }
    }

    async function handleLinkAddress(event, storeId, addressId) {

        if (event) {
            await DeliveryRoutesService.linkAddressToStore(storeId, addressId).then(res => {
                let newIds = [...checkedIds]
                newIds.push(addressId)
                setCheckedIds(newIds)
                toast.success('Endereço vinculado')
            }
            ).catch(err =>
                toast.error('Erro ao vincular endereço')
            )
        } else {
            await DeliveryRoutesService.unlinkAddressToStore(storeId, addressId).then(res => {
                let newIds = checkedIds.filter(item => item !== addressId)
                setCheckedIds(newIds)
                toast.info('Endereço desvinculado!')
            }
            ).catch(err =>
                toast.error('Erro ao desvincular endereço')
            )
        }
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable

                pauseOnHover />
            <div className='rowtabs'>
                <div className="nav nav-tabs" style={{ borderBottom: 'none' }}>
                    <a
                        onClick={() => setVisibleView('CONFIGS')}
                        className={
                            visibleView == 'CONFIGS'
                                ? 'nav-item ,nav-link, active'
                                : 'nav-item ,nav-link'
                        }

                    >
                        <VscGear></VscGear>
                        Configurações gerais
                    </a>
                    <a
                        onClick={() => setVisibleView('TAXES')}
                        className={
                            visibleView == 'TAXES'
                                ? 'nav-item ,nav-link, active'
                                : 'nav-item ,nav-link'
                        }
                    >
                        <Cardadd />
                        Acréscimos na venda
                    </a>
                    <a
                        onClick={() => setVisibleView('LOCALES')}
                        className={
                            visibleView == 'LOCALES'
                                ? 'nav-item ,nav-link, active'
                                : 'nav-item ,nav-link'
                        }
                    >

                        <FiMapPin />
                        Locais de retirada
                    </a>
                    <a
                        onClick={() => setVisibleView('COMPANY')}
                        className={
                            visibleView == 'COMPANY'
                                ? 'nav-item ,nav-link, active'
                                : 'nav-item ,nav-link'
                        }
                    >
                        <FaStore />
                        Dados da empresa
                    </a>
                </div>
            </div>


            <div className="condition-body">
                <form className="form formContainerSellparams"
                    onSubmit={handleSubmit(onSubmit)}>
                    {visibleView == 'CONFIGS' ?
                        <div>

                            <div className='containerInputsParams' style={{
                                //   'display': 'grid',
                                //   'gridTemplateColumns': 'repeat(auto-fill, minmax(270px, 300px))',
                                //   'gap': 30,
                                //   'justifyContent': 'center',
                                //   'alignItems': 'end'


                            }}>
                                <div className="form-group">
                                    <Select
                                        description="Nível"
                                        value={level}
                                        name="level"
                                        label_key="name"
                                        value_key="value"
                                        blank_label="---"
                                        register={register({ required: true })}
                                        onChange={(e: any) => setLevel(e.target.value)}
                                        options={levelList && levelList.map((n, index) => {
                                            let desc = n.descricao ? n.nivel + ' ' + n.descricao : n.nivel
                                            return { key: index, value: n.nivel, name: desc }
                                        })}
                                    // options={Array.from(Array(15).keys()).map((n) => {
                                    //     return { value: n + 1, name: n + 1 }
                                    // })}
                                    />

                                    {errors.level && (
                                        <small className="error form-text text-muted">
                                            Campo obrigatório!
                                        </small>
                                    )}
                                </div>
                                <div className="form-group">
                                    <Select
                                        value={delivery}
                                        description="Realiza Entrega"
                                        blank_label="Selecione entrega"
                                        label_key="name"
                                        value_key="value"
                                        name="delivery"
                                        register={register({ required: true })}
                                        onChange={(e: any) => setDelivery(e.target.value)}
                                        options={[
                                            { name: 'SIM', value: '1' },
                                            { name: 'Não', value: '0' },
                                        ]}
                                    />
                                    {errors.delivery && (
                                        <small className="error form-text text-muted">
                                            Campo obrigatório!
                                        </small>
                                    )}
                                </div>
                                <div className="form-group">
                                    <Select
                                        value={salesman}
                                        description="Vendedor"
                                        blank_label="Selecione um vendedor"
                                        label_key="name"
                                        value_key="value"
                                        name="seller"
                                        register={register({ required: true })}
                                        onChange={(e: any) => setSalesmen(e.target.value)}
                                        options={listSales.map((l) => {
                                            return { name: l.name, value: l.id }
                                        })}
                                    />
                                    {errors.seller && (
                                        <small className="error form-text text-muted">
                                            Campo obrigatório!
                                        </small>
                                    )}
                                </div>
                                <div className="form-group">
                                    <Select
                                        value={route}
                                        blank_label="Escolha a rota"
                                        description="Rota"
                                        label_key="name"
                                        value_key="value"
                                        name="route"

                                        register={register({ required: true })}
                                        onChange={(e: any) => setRoute(e.target.value)}
                                        options={routes.map((r) => ({ name: r.description, value: r.id }))}
                                    />
                                    {errors.route && (
                                        <small className="error form-text text-muted">
                                            Campo obrigatório!
                                        </small>
                                    )}
                                </div>
                                {delivery == '1' && (
                                    <div className="form-group">
                                        <Currency
                                            name="fatMin"
                                            description="Faturamento mínimo para entrega"
                                            value={minimumDeliveryBilling}
                                            onChange={(formatedValue, value: number) =>
                                                setMinimumDeliveryBilling(value)
                                            }
                                        // register={}
                                        />
                                        {needMinDeliveryBilling && (
                                            <small className="error form-text text-muted">
                                                O valor precisa ser maior que 0
                                            </small>
                                        )}

                                    </div>
                                )}

                                {
                                    //           {delivery == '1' ? (
                                    //               <div className="form-group column col-md-4">
                                    //                   <Currency
                                    //                       description="Faturamento mínimo para entrega"
                                    //                       value={minimumDeliveryBilling}
                                    //                       onChange={(formatedValue, value: number) =>
                                    //                           setMinimumDeliveryBilling(value)
                                    //                       }
                                    //                   />
                                    //               </div>
                                    //           ) : (
                                    //               ''
                                    //           )}

                                }
                                <div className="form-group">
                                    <Currency
                                        name="fatMinRet"
                                        description="Faturamento mínimo para retirada"
                                        value={minimumWithdrawalBilling}
                                        onChange={(formatedValue: string, value: number) =>
                                            setMinimumWithdrawalBilling(value)
                                        }

                                    />
                                    {needMinWithdrawalBilling && (
                                        <small className="error form-text text-muted">
                                            O valor precisa ser maior que 0
                                        </small>
                                    )}
                                </div>

                                <div className="form-group">
                                    <Select
                                        value={fractionalPurchase}
                                        description="Venda por Multiplos na entrega"
                                        blank_label="Venda por Multiplos na entrega"
                                        label_key="name"
                                        value_key="value"
                                        name="fractionalPurchase"
                                        register={register({ required: true })}
                                        onChange={(e: any) =>
                                            setFractionalPurchase(e.target.value)
                                        }
                                        options={[
                                            { name: 'SIM', value: '1' },
                                            { name: 'Não', value: '0' },
                                        ]}
                                    />
                                    {errors.delivery && (
                                        <small className="error form-text text-muted">
                                            Campo obrigatório!
                                        </small>
                                    )}
                                </div>

                                <div className="form-group">
                                    <Select
                                        name="group"
                                        value={group}
                                        value_key="id"
                                        label_key="name"
                                        options={groups}
                                        description="Grupo de produtos"
                                        blank_label="Selecione um grupo"
                                        onChange={(e: any) => setGroup(e.target.value)}
                                    />
                                    {errors.group && (
                                        <small className="error form-text text-muted">
                                            Campo obrigatório!
                                        </small>
                                    )}
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }} className="form-group">
                                    <CheckBoxCustom
                                        value={1}
                                        change={(e) => {
                                            setAcceptPix((prev) => prev === 1 ? 0 : 1);
                                        }}
                                        description={'Aceita pix?'}
                                        checked={acceptPix ? true : false}
                                    />
                                    <CheckBoxCustom
                                        value={fieldCOntrol}
                                        // change={(e, stats) => (e, stats)}
                                        change={(e, stats) => stats ? setFieldControl(1) : setFieldControl(0)}
                                        checked={fieldCOntrol == 1}
                                        description={'Usa Integração FieldControl ?'}
                                    ></CheckBoxCustom>
                                </div>
                            </div>

                            <div style={{
                                'marginTop': 10,
                                'display': 'grid',
                                'gridTemplateColumns': 'repeat(auto-fit, minmax(300px, 950px))',
                                'gap': 30,
                                'justifyContent': 'center',
                                'alignItems': 'end'
                            }}>
                                <div style={{
                                    'display': 'flex',
                                    'flexDirection': 'row',
                                    'justifyContent': 'space-between',
                                    'alignItems': 'flex-end'

                                }}>
                                    <div style={{}}>
                                        <div className="form-group">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label className="form-label form-label-direct-sale-parameter">
                                                        Dias de entrega
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                        <div style={{

                                            'display': 'flex',
                                            'flexDirection': 'row',
                                            'flexWrap': 'wrap',
                                            'gap': '20px'
                                        }}>

                                            {DateService.weekDays.map((day: Weekday) => (
                                                <div style={{
                                                    // 'margin': 5
                                                }} key={day.key}>
                                                    <CheckBoxCustom
                                                        value={day.value}
                                                        change={(e) => check(e)}
                                                        checked={
                                                            days != null &&
                                                            days.includes(day.value)
                                                        }
                                                        description={day.key}
                                                    ></CheckBoxCustom>

                                                </div>



                                                //           <div
                                                //               key={day.key}
                                                //               className="form-check form-check-inline"
                                                //           >
                                                //               <input
                                                //                   id={`checkbox` + day.key}
                                                //                   className="form-check-input form-input-direct-sale-parameter"
                                                //                   type="checkbox"
                                                //                   value={day.value}
                                                //                   checked={
                                                //                       days != null &&
                                                //                       days.includes(day.value)
                                                //                   }
                                                //                   onChange={check}
                                                //               />
                                                //               <label
                                                //                   className="form-check-label form-label-direct-sale-parameter"
                                                //                   htmlFor={`checkbox` + day.key}
                                                //               >
                                                //                   {day.key}
                                                //               </label>
                                                //           </div>
                                            ))
                                            }
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        : null}
                    {visibleView == 'TAXES' ?

                        <div>
                            <div style={{ minHeight: 250, maxHeight: '60vh' }}>

                                <div className="NewlistAddSales">
                                    <table className="newTableAddSales">
                                        <thead>
                                            <tr>
                                                <th className="">Nome do acréscimo</th>
                                                <th>Dado (Valor decimal)</th>
                                                <th className="">Tipo</th>

                                            </tr>
                                        </thead>

                                        <tbody>
                                            {salesTaxes && salesTaxes.data.length > 0 && (
                                                salesTaxes.data.map((item: any) =>
                                                    <tr>
                                                        <td data-label={'Nome do acréscimo'} className="">
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <CheckBoxCustom
                                                                    description=''
                                                                    checked={activeSalesTaxes && activeSalesTaxes.includes(item.id)}
                                                                    change={() => handleActiveTaxes(item.id)}
                                                                ></CheckBoxCustom>
                                                                {item.description}
                                                            </div>
                                                        </td>
                                                        <td data-label={'Dado (Valor decimal)'} className="">{item.value}</td>
                                                        <td data-label={'Tipo'} className="">{item.value_type === "%" ? 'Percentual' : 'Valor'}</td>

                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>

                                </div>
                                {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 30 }}>

                                    <ButtonV3
                                        filled
                                        title='Salvar'
                                    ></ButtonV3>
                                </div> */}
                            </div>

                        </div>
                        : null

                    }
                    {visibleView == 'LOCALES' ?
                        <div>
                            <div style={{ minHeight: 250, maxHeight: '60vh' }}>
                                {
                                    deliveryRoutes && deliveryRoutes.length > 0 ?
                                        <div className={`listRepresentatives`}>
                                            <table className={`newTableRepresentatives`}>
                                                <thead>
                                                    <tr>
                                                        <th className="text-left">Descrição</th>
                                                        <th className="text-left">Estado/Cidade</th>
                                                        <th className="text-left">Rua/Número</th>
                                                        <th className="text-left">Complemento/Referência</th>
                                                        <th>Ações</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {deliveryRoutes.map(
                                                        (item: any) => (
                                                            <tr key={item.id}>
                                                                <td data-label='Descrição'>{item.descricao}</td>
                                                                <td data-label='Estado/Cidade'>
                                                                    <div className={`columTable`} >
                                                                        <section >
                                                                            {handlStateName(item.estado_id)}
                                                                        </section>
                                                                        <section>
                                                                            {handleCityName(item.cidade_id)}
                                                                        </section>

                                                                    </div>
                                                                </td>
                                                                <td data-label='Rua/Número'>
                                                                    <div className={`columTable`} >
                                                                        <section >
                                                                            {item.rua}
                                                                        </section>
                                                                        <section>
                                                                            {item.numero ? item.numero : '-'}
                                                                        </section>

                                                                    </div>
                                                                </td>
                                                                <td data-label='Complemento/Referência'>
                                                                    <div className={`columTable`} >
                                                                        <section >
                                                                            {item.complemento ? item.complemento : '-'}
                                                                        </section>
                                                                        <section>
                                                                            {item.referencia ? item.referencia : '-'}
                                                                        </section>
                                                                    </div>
                                                                </td>
                                                                <td data-label='Ações' className="text-center">
                                                                    <Switch
                                                                        className="status-switch"
                                                                        height={23}
                                                                        width={50}
                                                                        checkedIcon={false}
                                                                        uncheckedIcon={false}
                                                                        onColor="#F29C33"
                                                                        onChange={(e) => handleLinkAddress(e, company.id, item.id)}
                                                                        checked={checkedIds.includes(item.id)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                            {/* {routes.last_page > 1 ?
                                <PaginationCustomV2
                                    handleSelectPage={(page) => { }}
                                    paginationData={routes}
                                    paginationDataDescription={'de representantes.'}

                                />
                                : null} */}
                                        </div> : <div>Nenhum Endereço encontrado!</div>
                                }
                            </div>
                        </div>
                        : null
                    }
                    { visibleView == 'COMPANY' ?
                        <div>
                            <div className='containerInputsParams' style={{marginBottom: '1vh'}}>
                                <ToggleButton
                                inactiveLabel={<p className='switchButtonLabel'>CNPJ</p>}
                                activeLabel={<p className='switchButtonLabel'>CPF</p>}
                                value={isCpf}
                                onToggle={() => setIsCpf(!isCpf)}
                                colors={{
                                    activeThumb: {base: 'white'},
                                    inactiveThumb: {base: 'white',},
                                    active: {base: '#FF7110',hover: '#FF7110',},
                                    inactive: {base: '#FF7110',hover: '#FF7110',}
                                }}/>
                            </div>
                            
                            <div className='containerInputsParams'>
                                <div className="form-group">
                                    <Input value={cpf} onChange={(e: any) => setCpf(e.target.value)} description='CPF / CNPJ'
                                    mask={isCpf ? "999.999.999-99" : "99.999.999/9999-99"}/>
                                </div>
                                <div className="form-group">
                                    <Input value={name} onChange={(e: any) => setName(e.target.value)} description='Nome fantasia'/>
                                </div>
                                <div className="form-group">
                                    <Input value={social} onChange={(e: any) => setSocial(e.target.value)} description='Razão social'/>
                                </div>
                            </div>
                            <div className='containerInputsParams'>
                                
                                <div className="form-group">
                                    <Select
                                        name="States"
                                        value={state}
                                        description='Estado'
                                        onChange={(e: any) => setState(e.target.value)}
                                        options={regions ? regions.estados : []}
                                        blank_label={state ? handlStateName(state) : 'Selecione um estado'}
                                        value_key="est_id"
                                        label_key="est_nome"
                                    />
                                </div>
                                <div className="form-group">
                                        <Select
                                            name="cities"
                                            description="Cidade"
                                            value={city}
                                            onChange={(e: any) => setCity(e.target.value)}
                                            value_key="cid_id"
                                            label_key="cid_nome"
                                            options={cityOptions}
                                            blank_label={city ? handleCityName(city) : 'Selecione uma cidade'}
                                        />
                                    
                                </div>
                                <div className="form-group">
                                    <Input value={cep}  onChange={(e: any) => setCep(e.target.value)} description='CEP'/>
                                </div>
                            </div>
                            <div className='containerInputsParams'>
                                <div className="form-group">
                                    <Input value={district} onChange={(e: any) => setDistrict(e.target.value)} description='Bairro'/>
                                </div>
                                <div className="form-group">
                                    <Input value={address} onChange={(e: any) => setAddress(e.target.value)} description='Endereço'/>
                                </div>
                                <div className="form-group">
                                    <Input value={number} onChange={(e: any) => setNumber(e.target.value)} description='Numero'/>
                                </div>
                            </div>
                            <div className='containerInputsParams'>
                                <div className="form-group">
                                    <Input value={complement} onChange={(e: any) => setComplement(e.target.value)} description='complemento'/>
                                </div>
                            </div>
                        </div>
                        : null
                    }
                    {visibleView !== 'LOCALES' ?

                        <div style={{

                            display: 'flex',
                            justifyContent: 'flex-end'
                        }} className="form-group">
                            {
                                //    <Button
                                //        type="submit"
                                //        className="align-self-end input-height c-btn-save"
                                //    >
                                //        Salvar
                                //    </Button>
                            }
                            <ButtonV3
                                filled
                                type={'submit'}
                                title='Salvar'
                            // onClick={() => { }}

                            />
                        </div>
                        : null}
                </form>
            </div>





        </>)
}

const mapStateToProps = (state: any) => ({
    user: state.user,
})

export default connect(mapStateToProps)(DirectSaleParameter)
