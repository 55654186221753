import QueryString from 'qs';
import HttpClient from './http.client'

export default class ClientService {

    static load_stores = async (params: any): Promise<any> => {

        return HttpClient.get(`/providers/clients`, { ...params, sort: 'l.loj_razao' })
    }

    static delete_store = (id: number): Promise<any> => {
        return HttpClient.post(`/clients/seller/condition/${id}`,{})
    }
    static updateCondition = (conditionId: number, data) => {
        HttpClient.put(
            `/providers/condition/${conditionId}`,
            data
        )
    }

    static sellers = async (id: number): Promise<any> => {
        return HttpClient.get(`/providers/clients/${id}/sellers`, { stype: 'REQUEST' })
    }

    static blacklist = async (): Promise<any> => {
        return HttpClient.get(`/providers/clients/blacklisted`)
    }

    static deactivate = async (id: number, seller_id: number): Promise<any> => {
        return HttpClient.delete(`/providers/clients/${id}/sellers/${seller_id}/deactivate`)
    }

    static discontinue_product = async (client_id: number, product_id: number, seller_id: number): Promise<any> => {
        return HttpClient.patch(`/providers/clients/${client_id}/products/${product_id}/discontinue?seller_id=${seller_id}`, {})
    }
    static continue_product = async (client_id: number, product_id: number, seller_id: number): Promise<any> => {
        return HttpClient.patch(`/providers/clients/${client_id}/products/${product_id}/continue?seller_id=${seller_id}`, {})
    }
    static getRequestedProducts = async (client_id: number, page?: ''): Promise<any> => {
        return HttpClient.get(`/providers/products/cotados?cli_id=${client_id}&page=${page}`)
    }
    static getComercialsReferences = async (client_id: number): Promise<any> => {
        return HttpClient.get(`/providers/products/referencias/comerciais?cli_id=${client_id}`)
    }
}
