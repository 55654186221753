import React, { useEffect, useState } from 'react'
import { Container, Item, ListContainer, SubItem } from './styles'
import { CheckBoxCustom } from 'components/checkBoxCustom'
import { IoFilterSharp } from 'react-icons/io5'
import ClassificationService from 'services/v3/classification.service'
import ButtonV3 from 'components/Buttonv3'
import DirectSaleProductService from 'services/DirectSaleProductService'
// import HttpClient from 'services/HttpClient'
import HttpClient from '../../../../services/v3/http.client'
import { ToastContainer, toast } from 'react-toastify';

interface props {
    classifications: any,
    onChangeValue: (data) => void,
    selecteds: [],
    proId: any,
    showSaveButton?: boolean
    closeCallback?: () => void
}



export default function ClassificationModal({ classifications, closeCallback, onChangeValue, selecteds, proId, showSaveButton }: props) {

    const [selectedIds, setSelectedIds] = useState<any>(selecteds)
    const [previousSelected, setPreviousSelected] = useState<any>()

    useEffect(() => {
        getSelectedClassification()
    }, [])

    async function getSelectedClassification(page = 1) {
        await ClassificationService.getclassificationSelected(proId).then(res => {
            setSelectedIds(res)
            setPreviousSelected(res)
        })
    }

    function handleSelectId(id, subId) {

        let newObj = { clas_id: id, sub_clas_id: subId, pro_id: proId }


        let selecteds = [...selectedIds]
        if (selecteds.some(item => item.clas_id == id)) {
            selecteds = selecteds.filter(item => item.clas_id !== id)
        } else {
            selecteds = [...selecteds, newObj]
        }
        setSelectedIds(selecteds)
    }

    useEffect(() => {

        onChangeValue(selectedIds)
    }, [selectedIds])

    function verifyDisabled(id, subid) {

        if (selectedIds.some(item => item.clas_id == id)) {
            let filtered = selectedIds.filter(item => item.clas_id == id)
            if (filtered[0].sub_clas_id == subid) {
                return false
            } else {

                return true
            }
        }
        else {
            return false
        }
    }

    async function handleSave() {

        HttpClient.post(`/providers/classificacao/sub/items`, selectedIds).then(res => {
            toast.success('Classificações salvas');
            // if (closeCallback) {
            //     closeCallback()
            // }
        }

        )
        //   }
    }
    return (

        <Container>
            <ToastContainer
                position="top-right"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable

                pauseOnHover />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonV3
                    filled
                    title='Salvar'
                    onClick={() => handleSave()}

                >
                </ButtonV3>
            </div>

            <ListContainer>
                {classifications.map((item: any, index) => (
                    <div>
                        <Item> <IoFilterSharp color='#ff7110' />
                            <p>
                                {item.descricao}
                            </p>
                        </Item>
                        {
                            item.sub_classificacao.length > 0 && item.sub_classificacao?.map((sub: any, subindex) => (
                                <SubItem>
                                    <CheckBoxCustom
                                        description=''
                                        disabled={verifyDisabled(item.id, sub.id)}
                                        change={() => handleSelectId(item.id, sub.id)}
                                        checked={selectedIds.some(sitem => sitem.sub_clas_id == sub.id)}
                                        value={sub.id} />
                                    {sub.nome}

                                </SubItem>
                            ))
                        }
                    </div>
                ))}

            </ListContainer>

            {/* <div className={`listRepresentatives`}>
        <table className={`newTableRepresentatives`}>
          <thead>
            <tr>
              <th colSpan={5} className="text-left">Descrição</th>
            </tr>
          </thead>
          <tbody>
            {classifications.map(
              (item: any, index) => (
                <>
                  <tr style={index % 2 == 0 ? { backgroundColor: '#fff' } : { backgroundColor: 'rgba(0,0,0,0.1) ' }} className="" key={item.id + 'desktop'}>
                    <td colSpan={5} >
                      <p>
                        {item.descricao}
                      </p>
                    </td>
                  </tr>

                  {item.sub_classificacao.length > 0 && item.sub_classificacao?.map(
                    (subitem: any, idx) => (
                      <tr
                        style={index % 2 == 0 ? { backgroundColor: '#fff' } : { backgroundColor: 'rgba(0,0,0,0.1) ' }}
                        className="desktopTr"
                      >
                        <td colSpan={5}>
                          <div style={{ display: 'flex', alignItems: 'center', gap: 15, marginLeft: 15 }}>


                            <p>
                              {subitem.nome}
                            </p>

                          </div>
                        </td>

                      </tr>
                    ))}
                </>
              )
            )}
          </tbody>
        </table>
      </div> */}
        </Container>
    )
}
